import gql from "graphql-tag";

export const PAGINATE_EMPLOYEE_EVALUATION = gql`
  query waitingPerformanceEvaluationsByEvaluatee($employee: ID!, $published: Boolean!, $pagination: Pagination!) {
    waitingPerformanceEvaluationsByEvaluatee(employee: $employee, published: $published, pagination: $pagination) {
      pageInfo {
        totalElements
        totalPages
      }
      content {
        id
        published
        publishedDateTime
        totalActualScore
        kpiTotalActualScore
        competenceTotalActualScore
        evaluator {
          id
          firstName
          lastName
        }
        evaluatee {
          id
          firstName
          lastName
          profile {
            imageUrl
          }
          employment {
            branch {
              name
            }
            jobInfo {
              name
            }
          }
        }
        evaluation {
          id
          name
          expirationBeginDate
          expirationEndDate
          validationBeginDate
          validationEndDate
          template {
            kpiWeight
            competenceWeight
          }
          scoreSettings {
            name
            minScore
            maxScore
            scoreSettingsDescriptions {
              fromScore
              toScore
              description
            }
          }
        }
        kpiCategories {
          id
          totalActualScore

          category {
            category {
              name
              color
              type
              id
            }
          }
          items {
            id
            score
            description
            actualScore
            item {
              weight
              item {
                id
                name
                type
                color
              }
            }
          }
        }
        competenceCategories {
          id
          totalActualScore

          category {
            category {
              name
              color
              type
              id
            }
          }
          items {
            id
            score
            description
            actualScore
            item {
              weight
              item {
                id
                name
                type
                color
              }
            }
          }
        }
      }
    }
  }
`;
export const EMPLOYEE_EVALUATION_DETAIL = gql`
  query performanceEvaluationResult($id: ID!) {
    performanceEvaluationResult(id: $id) {
      id
      published
      publishedDateTime

      evaluatee {
        firstName
        lastName
        profile {
          imageUrl
        }
        employment {
          branch {
            name
          }
          jobInfo {
            name
          }
        }
      }
      evaluation {
        name
        expirationBeginDate
        expirationEndDate
        validationBeginDate
        validationEndDate
        template {
          kpiWeight
          competenceWeight
        }
        scoreSettings {
          name
          minScore
          maxScore
          scoreSettingsDescriptions {
            fromScore
            toScore
            description
          }
        }
      }
      kpiCategories {
        id
        totalActualScore

        category {
          category {
            name
            color
            type
            id
          }
        }
        items {
          id
          score
          description
          actualScore
          item {
            weight
            item {
              id
              name
              type
              color
              comment
              description
            }
          }
        }
      }
      competenceCategories {
        id
        totalActualScore

        category {
          category {
            name
            color
            type
            id
          }
        }
        items {
          id
          score
          description
          actualScore
          item {
            weight
            item {
              id
              name
              type
              color
              comment
              description
            }
          }
        }
      }
    }
  }
`;
