<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-flex xs3>
            <v-select
              v-model="table.criteria.published"
              :items="performanceStatus"
              :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
              :label="$t('global.status')"
            />
          </v-flex>
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td>
                <employee-profile :employee="row.item.evaluatee" />
              </td>
              <td>
                {{ row.item.evaluation.name }}
              </td>
              <td>
                {{ row.item.evaluation.expirationBeginDate | formattedDate }} -
                {{ row.item.evaluation.expirationEndDate | formattedDate }}
              </td>
              <td>
                {{ row.item.evaluation.validationBeginDate | formattedDate }} -
                {{ row.item.evaluation.validationEndDate | formattedDate }}
              </td>
              <template v-if="table.criteria.published">
                <td>
                  {{ (row.item.kpiTotalActualScore && row.item.kpiTotalActualScore.toFixed(2)) || "-" }}
                </td>

                <td>
                  {{ (row.item.competenceTotalActualScore && row.item.competenceTotalActualScore.toFixed(2)) || "-" }}
                </td>
                <td class="text-no-wrap">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ row.item.totalActualScore && row.item.totalActualScore.toFixed(2) }} /
                      {{ row.item.evaluation.scoreSettings.maxScore }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption font-weight-medium">{{
                      getScoreDescription(row.item.totalActualScore, row.item.evaluation.scoreSettings)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </td>
              </template>
              <td class="text-no-wrap">
                <v-btn color="primary" depressed v-if="!row.item.published" @click.prevent.stop="selectTableRow(row)">
                  <v-icon :size="22" left>mdi-gesture-tap</v-icon>
                  {{ $t("hr.performance.published_start") }}
                </v-btn>
                <template v-else>
                  <v-chip label color="green" class="" dark small>
                    <v-icon left>mdi-check</v-icon>
                    {{ $t("hr.performance.evaluation_done") }}
                  </v-chip>
                  <v-btn
                    class="ml-3"
                    color="green"
                    depressed
                    fab
                    small
                    @click.prevent.stop="navigateToResult(row.item)"
                  >
                    <v-icon color="white">mdi-chart-arc</v-icon>
                  </v-btn>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view :item="selectedRow" @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Performance Component Logic
   * @date 28.07.2020
   */

  import { PAGINATE_EMPLOYEE_EVALUATION } from "./query";

  export default {
    name: "PerformanceList",
    data: vm => ({
      performanceStatus: [
        {
          text: vm.$t("hr.performance.my_evaluatees"),
          value: false
        },
        {
          text: vm.$t("hr.performance.my_evaluated"),
          value: true
        }
      ],
      selectedRow: null,
      table: {
        loading: false,
        data: [],
        criteria: {
          employee: null,
          published: false
        },
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "evaluation.validationBeginDate",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.performance.table.evaluatee"),
            value: "evaluatee",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.performance.table.name"),
            value: "name",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.performance.expiration_dates"),
            value: "expirationBeginDate",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.performance.validation_date"),
            value: "validationBeginDate",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: "",
            value: "",
            align: "left",
            sortable: false,
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      },

      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      },
      "table.criteria.published"(published) {
        if (published) {
          this.table.headers.splice(
            4,
            0,
            {
              text: this.$t("hr.performance.results.kpi_score"),
              value: "kpi",
              align: "left",
              sortable: false,
              class: ["primary--text"]
            },
            {
              text: this.$t("hr.performance.results.competence_score"),
              value: "competence",
              align: "left",
              sortable: false,
              class: ["primary--text"]
            },
            {
              text: this.$t("hr.performance.results.total_score"),
              value: "",
              align: "left",
              sortable: false,
              class: ["primary--text"]
            }
          );
        } else {
          this.table.headers.splice(4, 3);
        }
      }
    },
    methods: {
      getScoreDescription(totalScore, scoreSettings) {
        console.log(totalScore, scoreSettings);
        let scoreSettingsDescriptions = scoreSettings.scoreSettingsDescriptions;
        let lastScore = scoreSettingsDescriptions[scoreSettingsDescriptions.length - 1];
        if (lastScore && totalScore >= lastScore.fromScore && totalScore <= lastScore.toScore) {
          return lastScore.description;
        } else {
          for (let i = 0; i < scoreSettingsDescriptions.length - 1; i++) {
            let currentScore = scoreSettingsDescriptions[i];
            if (totalScore >= currentScore.fromScore && totalScore < currentScore.toScore) {
              return currentScore.description;
            }
          }
        }
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: PAGINATE_EMPLOYEE_EVALUATION,
            variables: {
              employee: this.$store.state.auth.user.id,
              published: this.table.criteria.published,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.waitingPerformanceEvaluationsByEvaluatee.content || [];
              this.table.totalItems = data.waitingPerformanceEvaluationsByEvaluatee.pageInfo.totalElements || 0;
              this.table.totalPages = data.waitingPerformanceEvaluationsByEvaluatee.pageInfo.totalPages || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      selectTableRow(row) {
        if (!row) return;
        if (row.item) {
          this.selectedRow = row.item;
          this.navigateToDetail();
        }
      },
      navigateToDetail() {
        let routerParams = {
          id: this.$helpers.encodeID(this.selectedRow.id)
        };
        this.$router.push({
          name: "performance_evaluatee",
          params: routerParams
        });
      },
      navigateToResult(item) {
        this.selectedRow = item;
        let routerParams = {
          id: this.$helpers.encodeID(this.selectedRow.id)
        };
        this.$router.push({
          name: "performance_result",
          params: routerParams
        });
      }
    }
  };
</script>

<style scoped></style>
